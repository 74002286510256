import { Component } from '@angular/core';

@Component({
  selector: 'app-pages-not-found',
  templateUrl: './pages-not-found.component.html',
  styleUrls: ['./pages-not-found.component.scss']
})
export class PagesNotFoundComponent {

}
