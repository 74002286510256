import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private apollo: Apollo, private router: Router) {}

    // Usamos un BehaviorSubject para almacenar el valor actual
    private dataCountry = new BehaviorSubject<boolean>(false);
    createCountry$ = this.dataCountry.asObservable();


  // TODO: esto lo engo que cambiar 1000 --> 40 y crear una nueva funcion para todos los paises
  getCountries(take: number | undefined, skip: number | undefined): Observable<any> {
    const COUNTRIES_QUERY = gql`
      query GetCountries($take: Int, $skip: Int) {
        getCountries(take: $take, skip: $skip) {
          countries {
            id
            name
          }
          totalRecords
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: COUNTRIES_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          take,
          skip,
        },
        errorPolicy: 'all',
      })
      .pipe(
        map((resp) => {
          return resp.data.getCountries;
        })
      );
  }

  deleteCountry(id: string): Observable<any> {
    const deleteCountryQery = gql`
      mutation DeleteCountry($id: String!) {
        deleteCountry(id: $id)
      }
    `;
    return this.apollo
      .mutate<any>({
        mutation: deleteCountryQery,
        fetchPolicy: 'no-cache',
        variables: {
          id: id,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data;
        })
      );
  }

  searchCountry(termino: string, take: number, skip: number): Observable<any> {
    const searchCountriesQuery = gql`
      query SearchCountry($termino: String!, $take: Int, $skip: Int) {
        searchCountry(termino: $termino, take: $take, skip: $skip) {
          countries {
            id
            name
          }
          totalRecords
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: searchCountriesQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          termino,
          take,
          skip,
        },
      })
      .pipe(
        map((resp) => {
          return resp.data.searchCountry;
        })
      );
  }

  setCountry(data: any): Observable<any> {
    const setPaisMutationQuery = gql`
      mutation Mutation($name: String!) {
        createCountry(name: $name)
      }
    `;

    return this.apollo
      .mutate<any>({
        mutation: setPaisMutationQuery,
        fetchPolicy: 'no-cache',
        variables: {
          name: data.name,
        },
      })
      .pipe(
        map((resp: any) => {

          //Notificamos de que se ha creado un cliente
          this.dataCountry.next(true);
          return resp.data.createCountry;
        })
      );
  }

  updateCountry(inputCountry: any): Observable<any> {
    const updateCountryQuery = gql`
      mutation UpdateCountry($country: CountryUpdate!) {
        updateCountry(country: $country)
      }
    `;

    return this.apollo
      .mutate<any>({
        mutation: updateCountryQuery,
        fetchPolicy: 'no-cache',
        variables: {
          country: { id: inputCountry.id, name: inputCountry.name },
        },
      })
      .pipe(
        map((resp) => {
          return resp.data;
        })
      );
  }
}
