import { Icountry } from "../country/country.model";

export interface Iprovince {
    id?:        string | undefined,
    name?:      string | undefined,
    countryId?: string | undefined
}

interface error {
    code?: string,
    message?: string
}

interface IclassProvince extends Iprovince {
    errors: error[],
    invalidField: [{
        name:  boolean, 
        country: boolean,
    }]      
}


export class Province {
    public data: IclassProvince | undefined = undefined;

    constructor() {
        this.data = {
            name: undefined,
            countryId: undefined,
            errors: [],
            invalidField:[{
                name: false,
                country: false
            }]
        }
    }

    set setName ( name: string | undefined ) {

        if (name && name.length <=2) {
        this.data?.errors.push({code:'name',message:'nombre inválido, debe tener entre 4-100 caracteres'});
        this.data!.invalidField[0].name = true;
        return;
        }
        
        this.data!.name = name;

    }

    set setCountry (countryId: string | undefined) {
        if (!countryId) {
            this.data?.errors.push({code:'country',message:'Pais no válido'});
            this.data!.invalidField[0].country = true;
            return;
        }
        this.data!.countryId = countryId;
    }
}