import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService],
})
export class LoginComponent  {

  public cargando:boolean = false;
  public loginForm = this.fb.group({
    email: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    pass: ["", [Validators.required]],
    recuerdame: [false, [Validators.required]],
  });


  constructor( private userService:UserService, 
               private fb: UntypedFormBuilder,
               public messageService: MessageService,
               private router: Router ) {}


  @HostListener("submit", ["$event"]) onSubmit(event: Event) {
    event.preventDefault();

    if (!this.loginForm.valid) {
      return;
    }
    let datos = {
      email: this.loginForm.controls["email"].value,
      pass: this.loginForm.controls["pass"].value,
    };

    // hacemos la peticion
    this.cargando = true;
    this.userService.login(datos).subscribe({
      next: (resp: any) => {
        if (resp.login.token.length >0) {
          this.messageService.add({
            key: "success",
            severity: "success",
            summary: "MDG Audiovisuales",
            detail: "Credenciales correctas",
          });

          this.cargando = false;

          if (this.loginForm.get("recuerdame")?.value == true) {
            localStorage.setItem("email", this.loginForm.get("email")?.value);
          }
          setTimeout(() => {
            this.messageService.clear();
            this.router.navigate(["/home"]);
          }, 100);
        }
      },
      error: (err:any) => {
        if (err.message=="Usuario inactivo"){
          this.messageService.add({
            key: "success",
            severity: "error",
            summary: "MDG Audiovisuales",
            detail: 'La cuenta no está activada. Rebise su corro electrónico',
          });
        }
        this.cargando = false;
        if (err == 'ApolloError: Credenciales invalidas') {
          this.messageService.add({
            key: "success",
            severity: "error",
            summary: "MDG Audiovisuales",
            detail: 'Credenciales Inválidas',
          });
          return;
        };

        if (err.message && err.message.includes('database server')) {
          this.messageService.add({
            key: "success",
            severity: "error",
            summary: "MDG Audiovisuales",
            detail: 'No Hay conexión con la Base de datos',
          });
        };

        this.messageService.add({
          key: "success",
          severity: "error",
          summary: "MDG Audiovisuales",
          detail: err.message || 'Error de Servidor',
        });
      },
    });
  }

  btnRecuerdame($event: Event): void {
    const recuerdame = ($event.target as HTMLTextAreaElement).value;
    this.loginForm.controls["recuerdame"].setValue(recuerdame);
  }
}
