import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, map } from "rxjs";
import { UserService } from "../services/user/user.service";
import { Apollo, gql } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class LoginGuard {
  constructor(
    private userService: UserService,
    private router: Router,
    private apollo: Apollo
  ) {}

  canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean>|boolean {
    const token = localStorage.getItem('user');

    // COMPROBAR TOKEN VÁLIDO
  if (token == null) {
    this.router.navigate(['/login']);
    return false;
  }
  
   return true;
   
  
}
  

    
  
}
