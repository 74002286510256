<p-toast key="success"></p-toast>

<div class="login-root animate__animated animate__fadeIn">
  <div
    class="box-root flex-flex flex-direction--column"
    style="min-height: 100vh; flex-grow: 1"
  >

    <div
      class="box-root padding-top--24 flex-flex flex-direction--column"
      style="flex-grow: 1; z-index: 9"
    >
      <div
        class="box-root padding-top--48 padding-bottom--24 flex-flex flex-justifyContent--center"
      >
        <h1><a href="#" rel="dofollow">MDG Audiovisuales</a></h1>
      </div>

      <div class="formbg-outer">
        <div class="formbg">
          <div class="formbg-inner padding-horizontal--48">
            <span class="padding-bottom--15">Acceder a tu Cuenta</span>
            <form [formGroup]="loginForm" id="stripe-login">
              <div class="field padding-bottom--24">
                <label for="email">Email</label>
                <input
                  formControlName="email"
                  type="email"
                  name="email"
                  class="ng-invalid ng-dirty"
                />
              </div>
              <div class="field padding-bottom--24">
                <div class="grid--50-50">
                  <label for="password">Contraseña</label>
                  <div class="reset-pass">
                    <a href="#" routerLink="/forgottenPass"
                      >Contraseña olvidada?</a
                    >
                  </div>
                </div>
                <!-- <input formControlName="pass" type="password" name="password" /> -->
                <p-password
                  formControlName="pass"
                  [toggleMask]="true"
                  [feedback]="false"
                  [style]="{ width: '100%' }"
                  [inputStyle]="{ width: '100%' }"
                ></p-password>
              </div>
              <div
                class="field field-checkbox padding-bottom--24 flex-flex align-center"
              >
                <label for="checkbox">
                  <input
                    type="checkbox"
                    (click)="btnRecuerdame($event)"
                    formControlName="recuerdame"
                    name="checkbox"
                  />
                  Recuerdarme
                </label>
              </div>
              <div class="field padding-bottom--24">
                <input
                  [disabled]="cargando"
                  type="submit"
                  name="submit"
                  value="Aceptar"
                />
              </div>
              <div class="field">
                <a class="ssolink" href="#">
                 Todos los derechos reservados</a
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
