<router-outlet> </router-outlet>

<p-sidebar
  appendTo="body"
  [(visible)]="sidebarVisible"
  (onHide)="hideSideBar()"
  [style]="{ width: '60%' }"
  >
<!-- Outlet secundario con nombre "sidebar" -->
<router-outlet name="sidebar"></router-outlet>

</p-sidebar>