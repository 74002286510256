import { NgModule, inject } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';
import {
  ApolloLink,
  InMemoryCache,
  split,
  type ApolloClientOptions,
} from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { HttpClientModule } from '@angular/common/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const uri = environment.uri;
const wsUrl = environment.wsUrl;
@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink): ApolloClientOptions<unknown> {
        // Enlace de carga de archivos
        // Ojo! cuando usas capollo-upload-client una versión superior a la 6 no
        // se ne necesita el enlace http
        const uploadLink = createUploadLink({
          uri: uri, 
          headers: {
            'Apollo-Require-Preflight': true,

          }
          
        });

        // Create an http link:
        // const http = httpLink.create({
        //   uri: 'http://localhost:4000/graphql',
        // });

        // Create a WebSocket link:
        const ws = new GraphQLWsLink(
          createClient({
            url: wsUrl,
            on: {
              connected: (resp) => {
                console.log(resp);
              },
              error: (err) => {
                console.log(err);
              },
              closed: (message) => {
                console.log(message);
              },
            },
          })
        );

        const router = inject(Router);
        const errorLink = onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {

              if(message=='No autorizado'){ 
                localStorage.clear();
                router.navigate(['/login']);
               }
            })
          }
           
          if (networkError) console.log(`[Network error]: ${networkError}`);
          
        });

        const auth = setContext((operation, context) => {
          const token = localStorage.getItem('user');

          if (token === null) {
            return {};
          } else {
            return {
              headers: {
                Authorization: token,
              },
            };
          }
        });


        const alllink = ApolloLink.from([errorLink, auth, uploadLink ]); // el orden de aparicion afecta

        // Using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
          // Split based on operation type
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === Kind.OPERATION_DEFINITION &&
              definition.operation === OperationTypeNode.SUBSCRIPTION
            );
          },
          ws,
          alllink
        );

        return {
          link,
          cache: new InMemoryCache(),
          // ... Options
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}


