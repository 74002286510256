import { Component, OnInit } from '@angular/core';
import { ComunService } from '../services/comun/comun.service';
import { MessageService } from 'primeng/api';
import { SharedService } from '../services/shared/shared.service';


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers:[MessageService]
})

export class PagesComponent {

  
  constructor( public comunService: ComunService) {  }

  
 
}



