<p-toast key="msg"></p-toast>

<form [formGroup]="formData">
    <div class="mb-4">
        <button (click)="btnNew()" pButton icon="pi pi-plus" styleClass="p-button-rounded"></button>
    </div>
    <div class="container">
        <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-pencil"></i></span>
            <input  formControlName="name" class="imputName" type="text" pInputText placeholder="Nombre Pais">
        </div>
        <div>
            <button pButton 
                    [disabled]="formData.invalid" 
                    *ngIf="formData.get('id')?.value == undefined"
                    class="btnGrabar" 
                    label="GRABAR" 
                    [loading]="loading" 
                    (click)="save()">
            </button>
            <button pButton 
                    *ngIf="formData.get('id')?.value != undefined"
                    [disabled]="formData.invalid" 
                    class="btnGrabar" 
                    label="ACTUALIZAR" 
                    [loading]="loading" 
                    (click)="update()">
            </button>
        </div>

    </div>
</form>