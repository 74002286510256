import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeNinvoicePipe } from './pipe-ninvoice.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [PipeNinvoicePipe, TruncatePipe],
  imports: [CommonModule],
  exports: [PipeNinvoicePipe, TruncatePipe],
})
export class PipesModule {}
