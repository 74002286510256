import {  NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PagesModule } from './@pages/pages.module';
import { LoginComponent } from './login/login.component';
import { Interceptor } from "./interceptor/interceptor";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ForgottenComponent } from './forgotten-pass/forgotten-pass.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { PagesNotFoundComponent } from './pages-not-found/pages-not-found.component';

import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';

import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import { newCountryComponent } from './@pages/country/new/new-country.component';
import { NewProvinceComponent } from './@pages/province/new/new-province.component';
import { NewTownComponent } from './@pages/town/new/new-town.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgottenComponent,
    ChangePassComponent,
    ActivateUserComponent,
    PagesNotFoundComponent,
    newCountryComponent,
    NewProvinceComponent,
    NewTownComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    PagesModule,
    ButtonModule,
    ToastModule,
    PasswordModule,
  ],

  exports: [
    NewProvinceComponent,
    newCountryComponent,
    NewTownComponent
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true, 
    },
    { provide: LOCALE_ID, useValue: "es" },
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
