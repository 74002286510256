import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LogoutGuard {
  constructor(
  
  ) {}

  canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean>|boolean {
    const token = localStorage.getItem('user');

   return !token ? true : false
   
  
}
  

    
  
}
