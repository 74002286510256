import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // Usamos un BehaviorSubject para almacenar el valor actual
  private dataSidebar = new BehaviorSubject<boolean>(false);
  sidebarVisible = this.dataSidebar.asObservable();

  constructor() { }

  // Método para actualizar el valor
  visibleSidebar(data: boolean) {
    this.dataSidebar.next(data);
  }
}
