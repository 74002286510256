import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Icountry } from '../../@pages/country/country.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProvinceService {
  // Usamos un BehaviorSubject para almacenar el valor actual
  private dataProvince = new BehaviorSubject<boolean>(false);
  createProvince$ = this.dataProvince.asObservable();

  constructor(private apollo: Apollo, private router: Router) {}

  getProvinces(take: number, skip: any): Observable<any> {
    const PROVINCES_QUERY = gql`
      query Provinces($take: Int, $skip: Int) {
        getProvinces(take: $take, skip: $skip) {
          provinces {
            id
            name
          }
          totalRecords
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: PROVINCES_QUERY,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          take,
          skip,
        },
      })
      .pipe(
        map((resp) => {
          if (resp.errors && resp.errors?.length > 0) {
            this.errorDebug(resp.errors[0]);
            return;
          }

          return resp.data;
        })
      );
  }

  getProvincesByCountry(country: Icountry | null): Observable<any> {
    const Busqueda = {
      id: country?.id,
      name: country?.name,
    };
    const provinces_query = gql`
      query GetProvinces($country: inputCountry) {
        getProvincesCountry(country: $country) {
          provinces {
            id
            name
          }
        }
      }
    `;
    return this.apollo
      .query<any>({
        query: provinces_query,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          country: Busqueda,
        },
      })
      .pipe(
        map((resp) => {
          return resp.data.getProvincesCountry;
        })
      );
  }

  getProvinceById(provinceId: string): Observable<any> {
    const query = gql`
      query GetProvinceById($getProvinceByIdId: String!) {
        getProvinceById(id: $getProvinceByIdId) {
          totalRecords
          provinces {
            name
            id
            country {
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo
      .query({
        query,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          getProvinceByIdId: provinceId,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.getProvinceById;
        })
      );
  }

  deleteProvince(id: string): Observable<any> {
    const deleteProvinceQuery = gql`
      mutation DeleteProvince($deleteProvinceId: String!) {
        deleteProvince(id: $deleteProvinceId)
      }
    `;
    return this.apollo
      .mutate<any>({
        mutation: deleteProvinceQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          deleteProvinceId: id,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data;
        })
      );
  }

  searchProvince(data: string, take: number, skip: number): Observable<any> {
    const searchProvinceQuery = gql`
      query Query($termino: String!, $skip: Int, $take: Int) {
        searchProvinces(termino: $termino, skip: $skip, take: $take) {
          provinces {
            id
            name
          }
          totalRecords
        }
      }
    `;

    return this.apollo
      .query<any>({
        query: searchProvinceQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          take,
          skip,
          termino: data,
        },
      })
      .pipe(
        map((resp) => {
          return resp.data.searchProvinces;
        })
      );
  }

  setProvince(data: any): Observable<any> {
    const setProvinceMutationQuery = gql`
      mutation CreateProvince($name: String!, $countryId: String!) {
        createProvince(name: $name, countryId: $countryId) {
          id
          message
          ok
        }
      }
    `;

    return this.apollo
      .mutate<any>({
        mutation: setProvinceMutationQuery,
        fetchPolicy: 'no-cache',
        variables: {
          name: data.name,
          countryId: data.countryId,
        },
      })
      .pipe(
        map((resp: any) => {
          this.dataProvince.next(true);
          return resp.data;
        })
      );
  }

  updateProvince(inputProvince: any): Observable<any> {
    const updateProvinceQuery = gql`
      mutation Mutation(
        $updateProvinceId: String!
        $name: String!
        $countryId: String!
      ) {
        updateProvince(
          id: $updateProvinceId
          name: $name
          countryId: $countryId
        )
      }
    `;

    return this.apollo
      .mutate<any>({
        mutation: updateProvinceQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          updateProvinceId: inputProvince.id,
          name: inputProvince.name,
          countryId: inputProvince.countryId,
        },
      })
      .pipe(
        map((resp) => {
          return resp.data;
        })
      );
  }

  errorDebug(err: any) {
    if (err.message == 'No autorizado') {
      this.router.navigate(['/login']);
      throw new Error('No autorizado');
    }
  }
}
