import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor( private userService:UserService, private router:Router ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.userService.getToken();
    
    // if (token) {
    //   req = req.clone({
    //     setHeaders: {
    //       authorization: `${token}`,
    //     },
    //   });
    // }

    return next.handle(req).pipe(
      

      catchError((err :any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // Specific handling for unauthorized errors         
            console.error('Unauthorized request:', err);
            // You might trigger a re-authentication flow or redirect the user here
          } 

        } 

        if (err.error.errors[0].message=="No autorizado") {
          this.userService.logout();
        }
        else {
          // Handle non-HTTP errors
          console.error('An error occurred:', err);
        }
  
        // Re-throw the error to propagate it further
        return throwError(() => err); 

      })
    )
   

  // return next.handle(req).pipe(
  //   tap(
  //     (event: HttpEvent<any>)=> {
    
  //       if (event instanceof HttpResponse) {
  //         //Manejar la respuesta
  //         if ( !event.body.errors) {

  //         } else {
            
  //           if (event.body.errors[0].message == 'No autorizado'){
  //           // BOORAR LOCALSTORAGE
  //             localStorage.removeItem('user');
  //             this.router.navigate(['/login']);

  //           }
           

  //         }
          
  //       }
  //     },(error : HttpErrorResponse ) => {
  //       if (error.status == 401) {
  //         console.log('manejar error');

  //           // this.router.navigate(['/login']);
  //       }
  //   }
  // ))
    
  }
}
