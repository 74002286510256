import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProgressRoutingModule } from './progress-routing.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { ProgressComponent } from '../progress/progress.component';

@NgModule({
  declarations: [ProgressComponent],
  imports: [
    CommonModule,
    ProgressRoutingModule,
    ProgressSpinnerModule
  ],
  exports:[ProgressComponent]
})
export class ProgressModule { }
