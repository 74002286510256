<!-- Mensajes -->
<p-toast key="msg"></p-toast>

<p-toast key="errorForm">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p innerHTML=" {{ message.detail }} "></p>
      </div>
    </div>
  </ng-template>
</p-toast>

<!--  -->
<div class="mb-4">
  <button
    (click)="btnNew()"
    pButton
    icon="pi pi-plus"
    styleClass="p-button-rounded"
  ></button>
</div>

<!-- contenedor -->
<div class="container w-full">
  <div class="p-inputgroup w-full">
    <span class="p-inputgroup-addon country"><i class="pi pi-check"></i></span>
    <p-dropdown
      [ngClass]="town.data!.invalidField[0].country == true ? 'error' : 'valid'"
      [options]="countries"
      dataKey="id"
      (onChange)="changeCountry()"
      [(ngModel)]="selectedCountry"
      optionLabel="name"
      [filter]="true"
      filterBy="name"
      [showClear]="true"
      placeholder="Selecciona un Pais"
      #PaisInput
    >
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
          <!-- <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.code.toLowerCase()" style="width: 18px"/> -->
          <div>{{ selectedCountry.name }}</div>
        </div>
      </ng-template>

      <ng-template let-country pTemplate="item">
        <div class="flex align-items-center gap-2">
          <!-- <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" style="width: 18px"/> -->
          <div>{{ country.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="p-inputgroup">
    <p-dropdown
      [ngClass]="
        town.data!.invalidField[0].province == true ? 'error' : 'valid'
      "
      class="province"
      [options]="provinces"
      optionLabel="name"
      dataKey="id"
      [(ngModel)]="selectedProvince"
      [filter]="true"
      filterBy="name"
      [showClear]="true"
      placeholder="Selecciona una Provincia"
    >
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="selectedProvince">
          <!-- <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.code.toLowerCase()" style="width: 18px"/> -->
          <div>{{ selectedProvince.name }}</div>
        </div>
      </ng-template>

      <ng-template let-province pTemplate="item">
        <div class="flex align-items-center gap-2">
          <!-- <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" style="width: 18px"/> -->
          <div>{{ province.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="p-inputgroup">
    <span class="p-inputgroup-addon"><i class="pi pi-pencil"></i></span>
    <input
      #name
      class="imputName"
      [ngClass]="town.data!.invalidField[0].name == true ? 'error' : 'valid'"
      [(ngModel)]="inputName"
      type="text"
      pInputText
      placeholder="Nombre Localidad"
      maxlength="100"
    />
  </div>
  <div>
    <button
      pButton
      [disabled]="
        inputName == undefined ||
        (inputName != undefined && inputName.length < 4)
      "
      *ngIf="town.data?.id == undefined"
      class="btnGrabar"
      label="GRABAR"
      [loading]="loading"
      (click)="btnSave()"
    ></button>
    <button
      pButton
      [disabled]="
        inputName == undefined ||
        (inputName != undefined && inputName.length < 4)
      "
      *ngIf="town.data?.id != undefined"
      class="btnGrabar"
      label="ACTUALIZAR"
      [loading]="loading"
      (click)="btnSave(true)"
    ></button>
  </div>
</div>
