import { Component, OnInit } from '@angular/core';
import { gql, Apollo } from "apollo-angular";
import { SharedService } from './services/shared/shared.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  sidebarVisible:boolean=false;
  title = 'app';

  constructor( private shareService:SharedService) {

  
  }
  ngOnInit(): void {
     // Nos suscribimos para recibir cambios en el dato compartido
     this.shareService.sidebarVisible.subscribe(data => this.sidebarVisible = data);
  }

  hideSideBar() {
    this.shareService.visibleSidebar(false);
  }
 
 
}
