import { Injectable } from '@angular/core';
import { Itown, IclassTown } from '../../@pages/town/town.model';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TownService {

  // Usamos un BehaviorSubject para almacenar el valor actual
  private dataTown = new BehaviorSubject<boolean>(false);
  createTown$ = this.dataTown.asObservable();

  constructor(private apollo: Apollo, private router: Router) {}

  createTown(town: Itown): Observable<any> {
    const create_query = gql`
      mutation CreateTown(
        $name: String!
        $countryId: String!
        $provinceId: String!
      ) {
        createTown(name: $name, countryId: $countryId, provinceId: $provinceId)
      }
    `;
    return this.apollo
      .mutate<any>({
        mutation: create_query,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          name: town?.name,
          countryId: town?.countryId,
          provinceId: town?.provinceId,
        },
      })
      .pipe(
        map((resp) => {
          this.dataTown.next(true);
          return resp.data;
        })
      );
  }

  getTowns(take: number, skip: number): Observable<any> {
    const load_query = gql`
      query Towns {
        getTowns {
          totalRecords
          towns {
            id
            name
            province {
              id
              name
            }
            country {
              id
              name
            }
          }
        }
      }
    `;
    return this.apollo
      .query<any>({
        query: load_query,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          take,
          skip,
        },
      })
      .pipe(
        map((resp) => {
          if (resp.errors && resp.errors?.length > 0) {
            this.errorDebug(resp.errors[0]);
            return;
          }
          return resp.data.getTowns;
        })
      );
  }

  getTownsByProvince(provinceId: string | null): Observable<any> {
    const query = gql`
      query GetTownsProvince($provinceId: String!) {
        getTownsProvince(provinceId: $provinceId) {
          towns {
            name
            id
          }
          totalRecords
        }
      }
    `;

    return this.apollo
      .query({
        query,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          provinceId,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.getTownsProvince;
        })
      );
  }

  getTownById(id: string): Observable<any> {
    const query = gql`
      query GetTownById($getTownByIdId: String!) {
        getTownById(id: $getTownByIdId) {
          towns {
            name
            province {
              name
              id
            }
            country {
              name
              id
            }
          }
        }
      }
    `;
    return this.apollo
      .query({
        query,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          getTownByIdId: id,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.getTownById;
        })
      );
  }

  updateTown(town: IclassTown): Observable<any> {
    const update_query = gql`
      mutation Mutation(
        $id: String!
        $name: String!
        $countryId: String!
        $provinceId: String!
      ) {
        updateTown(
          id: $id
          name: $name
          countryId: $countryId
          provinceId: $provinceId
        )
      }
    `;
    return this.apollo
      .mutate({
        mutation: update_query,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          id: town.id,
          name: town.name,
          countryId: town.countryId,
          provinceId: town.provinceId,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.updateTown;
        })
      );
  }

  searchTowns(termino: string, take: number, skip: number): Observable<any> {
    const search_query = gql`
      query Query($termino: String!) {
        searchTowns(termino: $termino) {
          totalRecords
          towns {
            province {
              id
              name
            }
            name
            id
          }
        }
      }
    `;

    return this.apollo
      .query({
        query: search_query,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          termino,
          take,
          skip,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.searchTowns;
        })
      );
  }

  deleteTown(id: string): Observable<any> {
    const delete_query = gql`
      mutation Mutation($deleteTownId: String!) {
        deleteTown(id: $deleteTownId)
      }
    `;

    return this.apollo
      .mutate({
        mutation: delete_query,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          deleteTownId: id,
        },
      })
      .pipe(
        map((resp) => {
          return resp.data;
        })
      );
  }

  private errorDebug(err: any) {
    if (err.message == 'No autorizado') {
      console.log('no autorizado, retornar a login');
      this.router.navigate(['/login']);
      throw new Error('No autorizado');
    }
  }
}
