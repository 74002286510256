<!-- Mensajes -->
<p-toast key="msg"></p-toast>

<p-toast key="errorForm" >
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p innerHTML=" {{ message.detail }} "> </p>
            </div>
           
        </div>
    </ng-template>
</p-toast>

<!--  -->
<div class="mb-4">
    <button (click)="btnNew()" pButton icon="pi pi-plus" styleClass="p-button-rounded"></button>
</div>
    <div class="container w-full">
        

        <div class="p-inputgroup w-full" >
            <span class="p-inputgroup-addon"><i class="pi pi-check"></i></span>
            <p-dropdown [ngClass]="province.data!.invalidField[0].country == true ? 'error' : 'valid'"  
                        [options]="countries"
                        [(ngModel)]="selectedCountry" 
                        dataKey="id"
                        optionLabel="name" 
                        [filter]="true" 
                        filterBy="name" 
                        [showClear]="true" 
                        placeholder="Selecciona un Pais">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                        <div>{{ selectedCountry.name }}</div>
                    </div>
                </ng-template>
                
                <ng-template let-country pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div>{{ country.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="p-inputgroup" >
            <span class="p-inputgroup-addon"><i class="pi pi-pencil"></i></span>
            <input 
                pInputText 
                #name 
                [(ngModel)]="inputName" 
                class="imputName" 
                [ngClass]="province.data!.invalidField[0].name == true ? 'error' : 'valid'" 
                type="text" 
                placeholder="Nombre Provincia" 
                maxlength="100"
            >

        </div>
        <div>
            <button pButton 
                    [disabled]="inputName == undefined ||  inputName != undefined && inputName.length < 4" 
                    *ngIf="province.data?.id == undefined"
                    class="btnGrabar" 
                    label="GRABAR" 
                    [loading]="loading" 
                    (click)="btnSave()">
            </button>

            <button pButton 
                    [disabled]="inputName == undefined ||  inputName != undefined && inputName.length < 4" 
                    *ngIf="province.data?.id != undefined"
                    class="btnGrabar" 
                    label="ACTUALIZAR" 
                    [loading]="loading" 
                    (click)="btnSave()">
            </button>
        </div>

    </div>