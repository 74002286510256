 export interface Itown {
    id?:        string | undefined,
    name?:      string | undefined,
    countryId?:  string | undefined,
    provinceId?: string | undefined

}

interface error {
    code?:    string,
    message?: string
}

 export interface IclassTown extends Itown {
    errors: error [];
    invalidField: [{
        name:     boolean, 
        country:  boolean,
        province: boolean
    }]     
}

export class Town  {
   
    public data: IclassTown | undefined = undefined;
    
    constructor() {
        this.data = {
            id: undefined,
            name: undefined,
            errors: [],
            invalidField:[{
                name:     false,
                country:  false,
                province: false
            }]
        }
    }


    set setName ( name: string | undefined ) {

        if (name && name.length <=4) {
        this.data?.errors.push({code:'name',message:'nombre inválido, debe tener entre 4-100 caracteres'});
        this.data!.invalidField[0].name = true;
        return;
        }
        
        this.data!.name = name;

    }

    set setCountry (countryId: string | undefined) {
        if (!countryId) {
            this.data?.errors.push({code:'country',message:'Pais no válido'});
            this.data!.invalidField[0].country = true;
            return;
        }
        this.data!.countryId = countryId;
    }
    set setProvince(provinceId: string | undefined) {
        if (!provinceId) {
            this.data?.errors.push({code:'province',message:'Provincia no válida'});
            this.data!.invalidField[0].province = true;
            return;
        }
        this.data!.provinceId = provinceId;
    }

}


