<p-toast key="success"></p-toast>

<div class="login-root animate__animated animate__fadeIn">
  <div
    class="box-root flex-flex flex-direction--column"
    style="min-height: 100vh; flex-grow: 1"
  >
    <div
      class="box-root padding-top--24 flex-flex flex-direction--column"
      style="flex-grow: 1; z-index: 9"
    >
      <div
        class="box-root padding-top--48 padding-bottom--24 flex-flex flex-justifyContent--center"
      >
        <h1><a href="#" rel="dofollow">MDG Audiovisuales</a></h1>
      </div>

      <div class="formbg-outer">
        <div class="formbg">
          <div class="formbg-inner padding-horizontal--48">
            <span class="padding-bottom--15">Cambiar Contraseña</span>
            <form [formGroup]="loginForm" id="stripe-login">
                <div class="field padding-bottom--24">
                    <div class="grid--50-50">
                      <label for="pass">Contraseña</label>
                    </div>
                    <!-- <input formControlName="pass" type="password" name="password" /> -->
                    <p-password
                      id="pass"
                      formControlName="pass"
                      required
                      [toggleMask]="true"
                      [feedback]="false"
                      [style]="{ width: '100%' }"
                      [inputStyle]="{ width: '100%' }"
                      styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                    ></p-password>
                    <small class="text-red-400">una may,una mini,sin espacios, al
                      menos 1 numero, algún caracter especial. alfanumérica 8 < 15 caracteres</small>
                  </div>
              <div class="field padding-bottom--24">
                <div class="grid--50-50">
                  <label for="pass2">Repita Contraseña</label>
                </div>
                <p-password
                  id="pass2"
                  formControlName="pass2"
                  required
                  [toggleMask]="true"
                  [feedback]="false"
                  [style]="{ width: '100%' }"
                  [inputStyle]="{ width: '100%' }"
                  styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                ></p-password>
              </div>
              <div
                class="field field-checkbox padding-bottom--24 flex-flex align-center"
              >
                
              </div>
              <div class="field padding-bottom--24">
                <input
                  
                  type="submit"
                  name="submit"
                  value="Aceptar"
                />
              </div>
              <div class="field">
                <a class="ssolink" href="#"
                  >&#64;2023 Todos los derechos reservados</a
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
